import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { flowRight } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import { CREATE_COMMENT } from '../../constants/interactions';
import {
  EXPERIMENT_RCE_LOADING_TYPE,
  EXPERIMENT_WIX_COMMENTS,
} from '@wix/communities-forum-client-commons/dist/src/constants/experiments';

import CardOverlay from '../../components/card-overlay';
import Loader from '@wix/communities-forum-client-commons/dist/src/components/loader';
import PostPageFooterWrapper from '../../components/post-page-footer-wrapper';
import CommentForm from '../../components/comment-form';
import LoginToComment from '../../components/login-to-comment';
import TypingList from '../../components/typing-list';
import withAuth from '../../hoc/with-auth';
import withDeviceType from '../../hoc/with-device-type';
import withDividerColor from '../../hoc/with-divider-color';
import {
  getIsRegistrationWithApproval,
  getIsEmailConfirmationOn,
} from '../../selectors/forum-data-selectors';
import { getTypingUsers } from '../../selectors/typing-selectors';
import {
  isEditor,
  isPreview,
  isSSR,
} from '../../../common/store/basic-params/basic-params-selectors';
import { isEditingComment } from '../../selectors/edited-comment-id-selectors';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withExperiment from '../../hoc/with-experiment';
import { HorizontalSeparator } from '../../components/separator';
import { withFastForm } from '../../../common/components/fast-form';
import commentFormSettings from '../../services/comment-form-settings';
import styles from './post-page-footer.scss';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';

import { ON_EXPAND, ON_FOCUS, ON_LOAD } from '../../constants/rce-loading-types';

export class PostPageFooter extends Component {
  componentWillUnmount = () => {
    this.props.clearTypingUsers();
  };

  handleCommentChange = () => this.props.emitTyping(this.props.postId, this.props.currentUser);

  handleCommentFormSubmitFromEditor = ({ values: comment }) => {
    this.props.interactionStarted(CREATE_COMMENT);
    return this.props
      .createCommentPromisified(this.props.postId, comment)
      .then(({ commentId }) => {
        const { fastForm, postId, postType, isPreview, fetchComment } = this.props;
        fastForm.resetForm();
        if (isPreview) {
          fetchComment({ postId, postType, commentId });
        }
      })
      .catch(() => {
        this.props.fastForm.stopSubmit();
      });
  };

  getInitialFormValues = () => {
    return {};
  };

  reportCommentIntent = () => {
    if (!this.hasReportedCommentIntent) {
      this.props.reportCommentIntent();
      this.hasReportedCommentIntent = true;
    }
  };

  renderCommentFormPlaceholder = () => (
    <div className={styles.commentFormPlaceholder}>
      <Loader />
    </div>
  );
  renderCommentForm = () => (
    <div
      className={styles.commentFormWrapper}
      style={{
        borderWidth: this.props.borderWidth,
        borderColor: this.props.dividerColor,
      }}
      data-hook="post-page-footer"
    >
      <CommentForm
        className={styles.commentForm}
        formInitialValues={this.getInitialFormValues()}
        onChange={this.handleCommentChange}
        onSubmit={this.handleCommentFormSubmitFromEditor}
        shouldDisableButton={!this.props.isEditingComment}
        renderFallback={this.renderCommentFormPlaceholder}
        resetFormOnCancel
        postId={this.props.postId}
        postType={this.props.postType}
        onFocus={this.reportCommentIntent}
      />
    </div>
  );

  render = () => {
    const {
      isMobile,
      isAuthenticated,
      typingUsers,
      isEditingComment,
      stopEditingComment,
      buttonClicked,
      isRegistrationWithApproval,
      isEmailConfirmationOn,
      isSSR,
      RCELoadingType,
      isWixCommentsEnabled,
    } = this.props;
    let content;

    if (isAuthenticated || (!isRegistrationWithApproval && !isEmailConfirmationOn)) {
      content = this.renderCommentForm();
    } else {
      content = <LoginToComment buttonClicked={buttonClicked} />;
    }

    const isDynamicRCELoadingEnabled = RCELoadingType === ON_FOCUS || RCELoadingType === ON_EXPAND;

    return (
      <PostPageFooterWrapper isMobile={isMobile} isTyping={Boolean(typingUsers.length)}>
        {isMobile && <HorizontalSeparator isLarge />}
        {isEditingComment && !isMobile ? <CardOverlay onClick={stopEditingComment} /> : null}
        <TypingList users={typingUsers} />
        {!isWixCommentsEnabled && (isDynamicRCELoadingEnabled || !isSSR) && content}
      </PostPageFooterWrapper>
    );
  };
}

PostPageFooter.propTypes = {
  postId: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  typingUsers: PropTypes.array.isRequired,
  emitTyping: PropTypes.func.isRequired,
  clearTypingUsers: PropTypes.func.isRequired,
  createCommentPromisified: PropTypes.func.isRequired,
  stopEditingComment: PropTypes.func.isRequired,
  isEditingComment: PropTypes.bool.isRequired,
  borderWidth: PropTypes.number,
  buttonClicked: PropTypes.func.isRequired,
  fastForm: PropTypes.object,
  interactionStarted: PropTypes.func,
  postType: PropTypes.string,
  reportCommentIntent: PropTypes.func,
  isRegistrationWithApproval: PropTypes.bool,
  isPreview: PropTypes.bool,
  fetchComment: PropTypes.func.isRequired,
  RCELoadingType: PropTypes.oneOf([ON_LOAD, ON_FOCUS, ON_EXPAND]),
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  typingUsers: getTypingUsers(state),
  isEditingComment: isEditingComment(state),
  createCommentPromisified: actions.createCommentPromisified,
  emitTyping: actions.emitTypingThrottled,
  clearTypingUsers: actions.clearTypingUsers,
  stopEditingComment: actions.stopEditingComment,
  buttonClicked: actions.buttonClicked,
  interactionStarted: actions.interactionStarted,
  isSSR: isSSR(state),
  reportCommentIntent: () =>
    actions.userEventsReplyOrCommentIntent({ type: 'comment', origin: 'button' }),
  isRegistrationWithApproval: getIsRegistrationWithApproval(state),
  isEmailConfirmationOn: getIsEmailConfirmationOn(state),
  isPreview: isEditor(state) || isPreview(state),
  fetchComment: actions.fetchComment,
});

export default flowRight(
  withFastForm(commentFormSettings, { skipInitialize: true }),
  connect(mapRuntimeToProps, [REDUCERS.COMMENT_EDIT]),
  withAuth,
  withCardBorderWidth,
  withDeviceType,
  withExperiment(
    {
      RCELoadingType: EXPERIMENT_RCE_LOADING_TYPE,
    },
    true,
  ),
  withExperiment({ isWixCommentsEnabled: EXPERIMENT_WIX_COMMENTS }),
  withDividerColor,
)(PostPageFooter);
